import * as Globals from './globals_veovita.js'

(function ($) {

    /**
     * this enables the user to pick a speicialist from our DB of specialists
     * There are two kinds: Doctors & Specialists
     * this is using the /rolodex endpoint
     * this plugin can be initiated in your form.js
     * bound to the form_pro-picker template
     * uses pro_picker.less for styling
     */
    $.fn.proPicker = function () {

        // Set bob constants
        let g = Globals;
        let event_namespace = '.pro-picker';

        /**
         * Show the proPicker 
         */
        let proPickerShow = function (e) {
            e.preventDefault();
            $($(e.target).data('target')).collapse('show');
        }

        /**
         * Hide the proPicker 
         */
        let proPickerHide = function (e) {
            e.preventDefault();
            $(e.target).closest('.collapse').collapse('hide');
        }

        /**
         * Selects the proPicker options and fills the form 
         * also it calls proPickerHide in the end
         */
        let proPickerSelect = function (e) {
            e.preventDefault();
            let $selected = $(e.target);
            let selection = JSON.parse($selected.data('json'));
            let vName = `${selection.title} ${selection.name}`;
            let vCity = selection.address;
            let vId = selection.id;

            if ($selected.hasClass('rolodexType-1')) {
                $('#w1-inputName_dr-framed').val(vName);
                $('#w1-inputCity_dr-framed').val(vCity);
                $('#w1-inputId_dr-framed').val(vId);
            } else {
                $('#w1-inputName_sp-framed').val(vName);
                $('#w1-inputCity_sp-framed').val(vCity);
                $('#w1-inputId_sp-framed').val(vId);
            }

            proPickerHide(e);
        }

        /**
         * Updates the Layout by showing the proPicker & loading the data
         * Maximum run of once per 250 milliseconds
         */
        let updateLayout = _.debounce(function (e) {
            proPickerShow(e);
            proPickerDisplayData(e);
        }, 250);

        /**
         * Loads and Displays the proPicker Data
         * makes ajax request to rolodex
         * uses response to create elements
         * removes previously created response elements
         */
        let proPickerDisplayData = function (e) {

            e.preventDefault();

            let collapseId = $(e.target).data('target');

            /**
             * prepares the payload for the rolodex request
             * differentiates between two different types of rolodex requests
             * @param id the data-target of the element that fired the event
             * @returns {string} json string used as the payload in the ajax request
             */
            let preparePayloadRolodex = function (dataTarget) {

                let payload = {};

                if (dataTarget === '#collapse-dr') {
                    payload = {
                        "name": filterInput($('.name_dr').val()).trim(),
                        "address": $('.city_dr').val().trim(),
                        "type": 1
                    }
                } else if (dataTarget === '#collapse-sp') {
                    payload = {
                        "name": filterInput($('.name_sp').val()).trim(),
                        "address": $('.city_sp').val().trim(),
                        "type": 2
                    }
                }
                return JSON.stringify(payload)
            }

            /**
             * simple check if an jQuery element has children
             * is later used to determine if a removal of response elements is needed
             * @returns {boolean}
             */
            let hasChildElements = function (tar) {
                if (tar.children().length > 0) {
                    return true
                }
                return false
            }

            /**
             * creates DOM elements 
             * removes previously created DOM elements
             * attaches a click event listener to the newly created DOM elements
             * @param res this is the response of the rolodex endpoint
             * @param rolodexType this is type of rolodex request that was made
             */
            let createElements = function (res, rolodexType) {

                let $target;

                if (rolodexType === 1) {
                    $target = $('#collapse-dr .pro-picker-options');
                } else {
                    $target = $('#collapse-sp .pro-picker-options');
                }

                // this removes elemnets containing the responses
                if (hasChildElements($target)) {
                    $target.empty();
                }

                // this adds elemnets containing the responses
                for (let i = 0; i < res.length && i < 10; i++) {
                    let value = res[i];

                    $('<div />', {
                        'html': `${value.title} ${value.name}<br/>${value.address}`,
                        'class': 'pro-picker-option rolodexType-' + rolodexType
                    }).data('json', JSON.stringify(value)).appendTo($target);
                }

                /**
                 * this event listener needs to be declared after the new elements are created
                 */
                $('.pro-picker-option').off(event_namespace);
                $('.pro-picker-option').on('click' + event_namespace, function (e) {
                    proPickerSelect(e);
                });

            }

            /**
             * the ajax tequest targeting the rolodex endpoint
             * attaches the paylaod to the request
             * calls createElements on success
             */
            $.ajax({
                url: "/rolodex",
                type: "POST",
                data: preparePayloadRolodex(collapseId),
                dataType: "json",
                cache: false,
                contentType: "application/json",
                success: function (response) {
                    let rolodexType = JSON.parse(preparePayloadRolodex(collapseId)).type;
                    createElements(response, rolodexType);
                },
                error: function (err) {
                    console.error(err);
                }
            });
        }

        /**
         * finds the correct name element that is used as the threshold to make an update to the layout
         * @param elm the input element that had an keyup or past event triggerd
         * @returns {object} jQuery object
         */
        let findNameInput = function (elm) {
            if (elm.classList.contains('city_dr')) {
                return $("input[name*='name_dr']")
            } else if (elm.classList.contains('city_sp')) {
                return $("input[name*='name_sp']")
            }
            return $(elm)
        }


        /**
         * filters out the strings that we do not want to send to the rolodex endpoint
         * by treating the string as an array of words 
         * @param input any string
         * @returns {string} without any member of the list of fobidden strings
         */
         let filterInput = function (input) {

            let excludeList = ['dr.', 'dr', 'prof.', 'prof', 'herr', 'frau'];
                
            let nameArr = input.split(" ");
            
            if (nameArr.length > 0) {
              let nameArrLow = nameArr.map(name => name.toLowerCase());
              
              excludeList.forEach(word => {
                  let id = nameArrLow.indexOf(word)
  
                  if(id > -1 && id < (nameArr.length-1)) {
                      nameArr.splice(id, 1)
                  }
              });
            }
            return nameArr.join(" ");
        }

        /**
         * clears the doctor and specialist IDs
         * so in the end only unaltered contact infos keep their IDs
         * @param elm the input element that had an keyup or past event triggerd
         */
        let clearContactIds = function (elm) {
            if (elm.classList.contains('city_dr') || elm.classList.contains('name_dr')) {
                $('#w1-inputId_dr-framed').val('');
            } else if (elm.classList.contains('city_sp') || elm.classList.contains('name_sp')) {
                $('#w1-inputId_sp-framed').val('');
            }
        }

        
        /**
         * listens on clicks and hides the pro-picker
         * event is passed on to determine which exact pro picker needs to be closed
         */
        $('.bob-close-pro-picker').off(event_namespace);
        $('.bob-close-pro-picker').on('click' + event_namespace, function (e) {
            proPickerHide(e);
        });

        /**
         * listens on keyup and paste 
         * clearsIds of specialists and doctors before doing anything else
         * will check if the dataPicker element has an name input with 3 characters in it
         * updates the layout 
         * updateLayout -> proPickerShow 
         * updateLayout -> proPickerDisplayData -> ajax -> createElements
         */
        $($(g.FRAME_FORM_SELECTOR + " input")).off(event_namespace);
        $($(g.FRAME_FORM_SELECTOR + " input")).on('keyup' + event_namespace + ' paste' + event_namespace, function (e) {

            clearContactIds(this);

            let filteredName = filterInput(findNameInput(this).val());

            // the respective name input needs to have 3 characters
            // there are two kind of name inputs: name_dr and name_sp 
            if (filteredName.trim().length > 2) {
                updateLayout(e);
            }
        });

    }

}(jQuery));