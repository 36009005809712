(function ($) {

  $.fn.partnerForm = function () {

    var msg = null;

    var successTimeout = null;

    var modalMsgHide = function (duration) {
      if(!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();

      });
      msg = null;
    };

    var modalMsgSuccess = function () {
      msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
      modalHide();
    };

    var modalHide = function () {
      e = $.Event('hide.bob.modal');
      $('.modal-form-context.modal').trigger(e);
      $('.modal-form-context.modal').fadeOut(400, function() {
        $(this).find('.modal-form-context .m-content').html('');
        var event = $.Event('afterhide.bob.modal');
        $('.modal-form-context.modal').trigger(event);
      });
      $('body').removeClass('modal-bob-open');
      modalMsgHide();
    };

    var modalMsgError = function () {
      msg = 'error';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-error').show();
      $('.modal-msg').fadeIn();
    };

    var modalMsgWarning = function () {
      msg = 'warning';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-warning').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function (e) {

      e.preventDefault();

      $('.form-group', '#modal-form-partner-mod').removeClass('has-error');

      modalMsgHide();

      $('#submit', '#modal-form-partner-mod form.frm-partner').prop('disabled', true);

      var data = {};
      var placeholder = {};
      $(':input', e.target).each(function() {
        if (this.name) {
          if (this.name.substr(-2) === "[]") {  // form fields with [] are arrays
            // get the cc[] values
            if (data[this.name.substr(0, this.name.length-2)] === undefined) {
              data[this.name.substr(0, this.name.length-2)] = [];
            }
            data[this.name.substr(0, this.name.length-2)].push($(this).val().trim());
          } else {
            data[this.name] = $(this).val().trim();
            // label[this.name] = $("label[for='"+$(this).attr('id')+"']", '#modal-form-pro-mod').text().trim();
            placeholder[this.name] = $(this).attr('placeholder') || "";
          }
        }
      });

      var notValid = false;

      if (data.email.trim() === "") {
        $('.form-group-email', '#modal-form-partner-mod').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-email', '#modal-form-partner-mod').removeClass('has-error');
        // data.description = placeholder.email + ": " + data.email + "\n\n" + data.description;
      }

      if (data.name.trim() === "") {
        $('.form-group-name', '#modal-form-partner-mod').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-name', '#modal-form-partner-mod').removeClass('has-error');
        // data.description = placeholder.name + ": " + data.name + "\n\n" + data.description;
      }

      if (notValid) {
        // enable button again
        $('#submit', '#modal-form-partner-mod form.frm-partner').prop('disabled', false);
        modalMsgWarning();
        return;
      }

      var dataEvent = $('#modal-form-partner-mod form.frm-partner').data('event');
      // if (!dataEvent) {
      //   dataEvent = 'conversionSuccess';
      // }
      // console.log('dataEvent', dataEvent);

      $('document').spinner('show');

      $.ajax({
        url: "/mail",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          // show message
          modalMsgSuccess();

          // tracking by google tag manager
          // google tag manager
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({"event": "conversionSuccessDoc"});
          }

          $('document').spinner('hide');
        },
        error: function (xhr, b, x) {
          var responseText = xhr.responseText;
          if(responseText && responseText.indexOf('{"error":"invalid email"}') > -1) {
            $('.form-control.email', '#modal-form-partner-mod').parent('.form-group').addClass('has-error');
          }
          // else if(responseText && responseText.indexOf('{"error":"RecordInvalid"}') > -1) {
          //   $('.form-control.name', '#modal-form-partner-mod').parent('.form-group').addClass('has-error');
          //   $('.form-control.text', '#modal-form-partner-mod').parent('.form-group').addClass('has-error');
          // }
          else {
            modalMsgError();
          }
          // enable button again
          $('#submit', '#modal-form-partner-mod form.frm-partner').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.modal').on('hide.bob.modal', function() {
      if (msg === 'error' || msg === 'warning') {
        modalMsgHide(300);
      }
    });

    $(document).off('.partnerform');

    $(document).on('submit.partnerform', '#modal-form-partner-mod form.frm-partner', function (e) {
      sendData(e);
    });

    $(document).on('click.partnerform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));
