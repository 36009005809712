export default class ValidationTarget {
    
    isValid = false;
    value;
    element;

    constructor(obj) {
        this.value = obj.value
        this.element = obj.element
    }

    handleValid() {
        this.isValid = true;
        this.element.removeClass('is-invalid');
    }

    handleInvalid() {
        this.isValid = false

        if (this.element.attr('name') === 'radio') {
            $('.form-group-radio', '.bob-frame').addClass('is-invalid');
            return
        }

        this.element.addClass('is-invalid');
    }

    hasFailedToValidate() {

        if (this.element.attr('name') === 'time') {
            return this.value === $('.time option')[0].value
        }
        
        else if (this.element.attr('name') === 'insurance') {
            return this.value === "2"
        }

        else if (this.element.attr('name') === 'phone') {
            return this.value.trim() === '' || _.validatePhone(this.value.trim()) === false
        }

        else if (this.element.attr('name') === 'email') {
            return this.value.trim() === '' || _.validateEmail(this.value.trim()) === false
        }
        
        else {
            return this.value.trim().length < this.element.attr('maxlength')
        }
    }

    hasSucceededToValidate() {

        if (this.element.attr('name') === 'radio') {
            return $('input[type=radio]:checked', '.bob-frame').length > 0
        }

    }
 }
