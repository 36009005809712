(function ($) {

    if ($('body.page_4').length > 0) {

        /**
         * this gets a specific url parameter 
         * @param  {} sParam
         */
        let getUrlParameter = function (sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };

        let handleNotValid = function () {
            $(".hero p").replaceWith($(".bob-content-onload"));
            $("#bob-frame-btn-1").hide();
        }

        /**
         * this is a simple plausability validation
         * @param  {} sParam
         */
        let frontendValidation = function (invite) {
            if(invite.length === 36 && typeof invite === 'string') {
                return true
            }
            return false
        }

        if (getUrlParameter('etcc_cmp') !== undefined) {
            _.setSessionStorage('etcc_cmp', getUrlParameter('etcc_cmp'));
        }

        if (getUrlParameter('invite') !== undefined) {
            
            let invite = getUrlParameter('invite');
            
            // simple validation before server validation
            if (frontendValidation(invite)) {

                // this request is returning the result of the server site validation
                // the invite is compared against our DB
                $.ajax({
                    url: "/enrollment/validate/" + invite,
                    type: "POST",
                    cache: false,
                    contentType: "application/json",
                    success: function (response) {

                       // write invite in session storage
                       _.setSessionStorage('invite', invite);
                    },
                    error: function (err) {
                        console.log(err)
                        handleNotValid();
                    }
                });
            } else {
                handleNotValid();
            }

        } else {
            handleNotValid();
        }



    }

}(jQuery));