(function ($) {

  var currentPage = 1

  $('#btnApptNext').click(() => {
    goToPage(currentPage + 1)
  })

  $('#btnApptBack').click(() => {
    goToPage(currentPage - 1)
  })

  $('#btnApptSubmit').click(() => {
    sendData()
  })

  function validateField(inputValue, fieldName) {
    const fields = {
      firstname      : ['empty', 'length'],
      lastname       : ['empty', 'length'],
      email          : ['empty', 'email'],
      phone          : ['empty', 'number', 'length']
    }

    var validationStatus = true

    fields[fieldName].forEach((validation) => {
      if (validationStatus) {
        switch (validation) {
          case 'empty':
            if (!inputValue || inputValue.trim() === '') {
              validationStatus = false
            }
            break

          case 'length':
            if (!inputValue || inputValue.length > 255) {
              validationStatus = false
            }
            break

          case 'email':
            const reEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!reEmail.test(inputValue.trim())) {
              validationStatus = false
            }
            break

          case 'number':
            if (isNaN(inputValue)) {
              validationStatus = false
            }
            break
        }
      }
    })

    return validationStatus
  }

  function validateCurrentPage(page) {
    if (page > currentPage && currentPage < 4) {
      var validationStatus = true
      switch (currentPage) {
        case 1:
          var fields = ['firstname', 'lastname']
          fields.forEach((field) => {
            if (validationStatus) {
              validationStatus = validateField($('#' + field).val(), field)
            }
          })
          break

        case 2:
          var fields = ['email', 'phone']
          fields.forEach((field) => {
            if (validationStatus) {
              validationStatus = validateField($('#' + field).val(), field)
            }
          })
          break
      }
      return validationStatus

    } else {
      return true
    }
  }

  function goToPage(page) {
    if (validateCurrentPage(page)) {
      currentPage = page

      Array.from(document.getElementsByClassName('form-page')).forEach((page, index) => {
        currentPage === (index + 1) ? page.classList.remove('d-none') : page.classList.add('d-none')
      })

      switch (page) {
        case 1:
          $('#btnApptBack'  ).addClass   ('d-none')
          $('#btnApptCancel').removeClass('d-none')
          break
      
        case 2:
          $('#btnApptCancel').addClass   ('d-none')
          $('#btnApptBack'  ).removeClass('d-none')
          break

        case 3:
          $('#btnApptSubmit').addClass   ('d-none')
          $('#btnApptNext'  ).removeClass('d-none')
          break

        case 4:
          $('#btnApptNext'  ).addClass   ('d-none')
          $('#btnApptSubmit').removeClass('d-none')
          break

        case 5:
          $('#btnApptBack'  ).addClass   ('d-none')
          $('#btnApptSubmit').addClass   ('d-none')
          $('#btnApptClose' ).removeClass('d-none')
      }

      $('#txtValidationError').addClass('d-none')
      
    } else {
      $('#txtValidationError').removeClass('d-none')
    }
  }

  async function sendData() {
    $('#btnApptBack'   ).addClass   ('d-none')
    $('#btnApptSubmit' ).addClass   ('d-none')
    $('#loadingSpinner').removeClass('d-none')

    let zammadData = {
      firstname : $('#firstname').val(),
      lastname  : $('#lastname').val(),
      gender    : $('#salutation').val(),
      email     : $('#email').val(),
      phone     : $('#phone').val(),
      diagnose  : $('#diagnose').val(),
      preference: parseInt($("input[name='preference']:checked").val()),
    }

    $.ajax({
      url: "/zammad",
      type: "POST",
      data: JSON.stringify(zammadData),
      dataType: "json",
      cache: false,
      contentType: "application/json",
      success: () => {
        $('#txtSubmitError').addClass('d-none')
        $('#loadingSpinner').addClass('d-none')
        goToPage(5)
      },
      error: (err) => {
        console.log(err)
        $('#loadingSpinner').addClass   ('d-none')
        $('#txtSubmitError').removeClass('d-none')
        $('#btnApptBack'   ).removeClass('d-none')
        $('#btnApptSubmit' ).removeClass('d-none')
      }
    })
  }

}(jQuery))