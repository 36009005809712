// this looks for a certain url parameter and stores it in session storage
// the session storage is giving bobframe its open on page load state
// it runs before the entire document is ready

if ($('body.page_1').length > 0) {

    /**
     * this is a simple plausability validation
     * @param  {} sParam
     */
    let validate = function (param) {
        if (typeof param === 'string') {
            return true
        }
        return false
    }

    if (_.getUrlParameter('kontaktformular') !== undefined) {

        let param = _.getUrlParameter('kontaktformular');

        if (validate(param)) {
            _.setSessionStorage('openFrameViaParam', param);
        }
    } else {
        _.setSessionStorage('openFrameViaParam', '0');
    }

}