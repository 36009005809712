(function ($) {

    /**
     * --- Validation
     */

    /**
     * This shows if an input is an actual date 
     * lets you specify your own format (for example 'YYYY/MM/DD')
     * you will need to install the day.js library https://www.npmjs.com/package/dayjs
     * @param  {} cDate
     * @param  {} format
     */
    let validateDate = function (cDate, format) {
 
        var dayjs = require('dayjs')
        var customParseFormat = require("dayjs/plugin/customParseFormat");
        dayjs.extend(customParseFormat);

        return dayjs(cDate, format, true).isValid()
    }

    /**
     * --- Add to lodash
     */

    _.mixin({
        'validateDate': validateDate,
    });

}(jQuery));