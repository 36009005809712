import _ from 'lodash';
import '../styles/main.less';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
// import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/util';

// Bob scripts form Libs
import '../scripts/fromLibs/activenav.js'
import '../scripts/fromLibs/ajaxform.js';
import '../scripts/fromLibs/anchorhelper.js'
import '../scripts/fromLibs/bottomize.js'
import '../scripts/fromLibs/flipper.js'
import '../scripts/fromLibs/inviewport.js'
import '../scripts/fromLibs/jumbo.js'
import '../scripts/fromLibs/lazyload.js'
import '../scripts/fromLibs/menuhelper.js'
import '../scripts/fromLibs/modal.js'
import '../scripts/fromLibs/preload.js'
import '../scripts/fromLibs/privacy.js'
import '../scripts/fromLibs/spinner.js'
import '../scripts/fromLibs/unbreak.js'
import '../scripts/fromLibs/util2.js';
import '../scripts/fromLibs/util_dayjs.js';

// Project specific scripts
import '../scripts/jquery.scrollTo.min.js';
import '../scripts/carousel.js';
import '../scripts/form_partner.js';
import '../scripts/onload_validation.js';
import '../scripts/onload_contact.js';
import '../scripts/pro_picker.js';

import '../scripts/globals_veovita.js';
import '../scripts/ValidationTarget.js';

import '../scripts/appointment.js';

import '../scripts/main.js';